import { Col, Row } from "react-bootstrap";
import { GlobalSettings } from "../../interfaces/globalSettingsInterface";

interface Props {
    settings:Array<GlobalSettings>;
    commissionsAssistences:number
}

const GlobalSettingsComponent = ({settings , commissionsAssistences}:Props) => {
    return (
        <>
        <hr className="my-4" />
              <h6 className="heading-small text-muted mb-4">
                Configuración Global
              </h6>
              <Row>
                <Col lg="6">
                  <label>Legislatura</label>
                  <p>{settings[0].legislature.value}</p>
                </Col>
                <Col lg="6">
                  <label>No. Total de Sesiones Plenarias</label>
                  <p>{settings[0].plenarySessions}</p>
                </Col>
                <Col lg="6">
                  <label>No. Total de Sesiones de la Comisiónes</label>
                  <p>
                    {commissionsAssistences}
                  </p>
                </Col>
                <Col lg="6">
                  <label>NO. DE SESIONES POR PERIODO </label>
                  <p>{settings[0].sessions_per_period}</p>
                </Col>
              </Row>
            
        </>
    );
};

export default GlobalSettingsComponent;