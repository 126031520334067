import { useEffect } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  resetStateAssignment,
  startNewAssignCommissions,
  startUpdatedAssignCommission,
} from "../../actions/assignmentCommissionActions";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";

interface Props {
  open: boolean;
  handleClose: () => void;
}

type Inputs = {
  assignmentID: string;
  name: string;
};

const ModalAssignmentCommission = ({ open, handleClose }: Props) => {
  const dispatch = useAppDispatch();
  const { update, assignmentCommissionSelected, assignmentCommissions } =
    useAppSelector((state) => state.assignment);
  const { loading } = useAppSelector((state) => state.ui);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<Inputs>({
    defaultValues: assignmentCommissionSelected,
  });

  useEffect(() => {
    if (assignmentCommissionSelected) {
      setValue("assignmentID", assignmentCommissionSelected.assignmentID);
      setValue("name", assignmentCommissionSelected.name);
    } else {
      setValue("assignmentID", "");
      setValue("name", "");
    }
    // eslint-disable-next-line
  }, [assignmentCommissionSelected, assignmentCommissions]);

  const handleResetForm = () => {
    dispatch(resetStateAssignment());
    handleClose();
  };

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (update) {
      dispatch(startUpdatedAssignCommission(data));
    } else {
      dispatch(startNewAssignCommissions(data));
    }
    handleResetForm();
  };

  return (
    <Modal show={open} onHide={handleResetForm}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {update
              ? "Editar encargo en comisión"
              : "Agregar encargo en comisión"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>ID Encargo de Comisión:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ej: PSO"
              {...register("assignmentID", {
                required: true,
              })}
            />
            {errors.name && (
              <p className="text-danger">El ID del encargo es requerida</p>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Nombre Encargo:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ej: Presidente"
              {...register("name", {
                required: true,
              })}
            />
            {errors.name && (
              <p className="text-danger">
                El nombre del encargo de la comisión es requerida
              </p>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleResetForm}>
            Cerrar
          </Button>
          <Button variant="primary" type="submit" disabled={loading}>
            Guardar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalAssignmentCommission;
