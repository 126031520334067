import { UsersInterface } from "../interfaces/usersInterfaces";
import { types } from "../types/types";

const initialState: UsersInterface = {
  users: [],
  update: false,
  userSelected: null,
};

export const usersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.usersLoad:
      return {
        ...state,
        users: action.payload,
      };
    case types.usersLoadAddNew:
      return {
        ...state,
        users: [...state.users, action.payload],
      };
    case types.userLoadActiveUpdate:
      return {
        ...state,
        update: true,
        userSelected: action.payload,
      };
    case types.userLoadUpdate:    
      return {
        ...state,
        users: state.users.map((user) => user.id === action.payload.id ? action.payload : user),
      };
    default:
      return state;
  }
};
