import { Container, Pagination, Row, Col, Form } from "react-bootstrap";

type Props = {
  pageCount: any;
  pageIndex: any;
  pageOptions: any;
  canPreviousPage: boolean;
  canNextPage: boolean;
  previousPage: any;
  nextPage: any;
  gotoPage: any;
};
const PaginationTable = ({
  pageCount,
  pageIndex,
  pageOptions,
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  gotoPage,
}: Props) => {
  return (
    <Container>
      <Row className="d-flex justify-content-end">
        <Col
        className="my-3 d-flex justify-content-end"
        xs={6}
        sm={6}
        md={3}
        lg={3}
        xl={3}
        >
        <span className="d-flex align-items-center">
              Página:
              <strong className="pl-2">
                {pageIndex + 1} de {pageOptions.length}
              </strong>
            </span>
        </Col>
        <Col
        className="my-2 d-flex justify-content-end"
        xs={6}
        sm={6}
        md={3}
        lg={3}
        xl={3}
        >
        <span className="d-flex align-items-center">
              | Ir a la página{" "}
              <Form.Control
                className="mx-2"
                type="number"
                min="1"
                max={pageOptions.length}
                size="sm"
                style={{ width: "80px" }}
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const PageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(PageNumber);
                }}
              />
            </span>
        </Col>
        <Col
          className="my-2 d-flex justify-content-end"
          xs={12}
          sm={12}
          md={3}
          lg={3}
          xl={3}
        >
          <Pagination>
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </Container>
  );
};

export default PaginationTable;
