import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";

interface Props {
  textDescription: string;
  filter:string;
  setFilter: (filterValue: any) => void;
}
const SearchTextComponet = ({ textDescription, filter, setFilter }: Props) => {
  return (
    <FormGroup className="d-flex">
      <InputGroup className="input-group-rounded input-group-merge">
        <Input
          aria-label="Search"
          className="form-control-rounded form-control-prepended ml-5"
          placeholder={textDescription}
          type="text"
          onChange={(e) => setFilter(e.target.value)}
          value={filter || ""}
        />
          <InputGroupText>
            <span className="fa fa-search" />
          </InputGroupText>
      </InputGroup>
    </FormGroup>
  );
};

export default SearchTextComponet;
