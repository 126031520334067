import { useEffect } from "react";
import { Card, Container, Row } from "react-bootstrap";
import PoliticalsTable from "../../components/politicalPartiesComponents/politicalsTable";
import Dashboard from "../../layouts/Dashboard/Dashboard";
const PoliticalPartiesScreen = () => {

  useEffect(() => {
    document.title = "Partidos Políticos";
  }, []);

  return (
    <Dashboard>
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <Card.Header className="border-0">
                <h3 className="mb-0"> Partidos Políticos</h3>
              </Card.Header>
             <Container>
              <PoliticalsTable />
             </Container>
            </Card>
          </div>
        </Row>
      </Container>
    </Dashboard>
  );
};

export default PoliticalPartiesScreen;
