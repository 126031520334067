import {useMemo } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import Swal from "sweetalert2";
import { activeUpdated, startDeletePoliticalParty, startNewPoliticalPartyExcelImport } from "../../actions/piliticalsPartiesAction";
import { COLUMNS_POLITICAL_PARTIES } from "../../helpers/columnsTables";
import { renameDataExcelPolitical } from "../../helpers/excelImportRenameData";
import { showAlert } from "../../helpers/messagesNotifications";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import useHandleShowComponents from "../../hooks/useHandleShowComponents";
import useImportExcel from "../../hooks/useImportExcel";
import { PoliticalParty } from "../../interfaces/politicalsPartiesInterfaces";
import InputUpload from "../uiComponents/inputUpload";
import PaginationTable from "../uiComponents/paginationTable";
import SearchTextComponet from "../uiComponents/searchTextComponet";
import TableComponent from "../uiComponents/tableComponent";
import ModalPoliticalParty from "./modalPoliticalParty";

const PoliticalsTable = () => {
  const dispatch = useAppDispatch()
  const { politicals } = useAppSelector((state) => state.political);
  const { type_user } = useAppSelector((state) => state.auth);
  const { setDataList } = useImportExcel();
  const columns = useMemo(
    () => [
       ...COLUMNS_POLITICAL_PARTIES, 
       {
        Header: "Opciones",
        accessor: "action",
        Cell: ({row}:any) =>(
          <>
            <Button variant="success" 
              onClick={()=>handleUpdatePolitical(row.original)}
            >
              <i className="fas fa-edit" />
            </Button>
            {
              type_user !== 2 ?
               <Button variant="danger"
                 onClick={()=>handleDeletePolitical(row.original.id)}
                >
                  <i className="far fa-trash-alt" />
                </Button>
                : null
            }
            
          </>
        )}
        // eslint-disable-next-line
      ],[]
  );
  const data = useMemo(() => politicals, [politicals]);
  const {
    state,
    headerGroups,
    page,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    nextPage,
    previousPage,
    gotoPage,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy, usePagination);
  const { show, handleShow, handleClose } = useHandleShowComponents();
  const { pageIndex, globalFilter } = state;

  const handleUpdatePolitical = (political: PoliticalParty) => {
    dispatch(activeUpdated(political));
    handleShow();
  };

  const handleDeletePolitical = (id: string) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¡Un partido politico eliminado no se puede recuperar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "No, Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "Eliminado!",
          "El partido político seleccionado ha sido eliminado.",
          "success"
        );
        dispatch(startDeletePoliticalParty(id));
      }
    });
  };

  const createListPolitical = (dataList:any) => {
    dataList.forEach((politician: any) => {
      const data = renameDataExcelPolitical(politician);
      dispatch(startNewPoliticalPartyExcelImport(data));
    });
    setDataList([]);
    showAlert(
      "Importación Exitosa",
      "La importación se ha guardado de forma correcta",
      "success"
    );
  };
  
  return (
    <>
      <Row className="mx-2">
        <Col className="my-3" xs={12} lg={4} xl={6}>
          {
            type_user !== 2 ?
            <>
              <Button variant="success" onClick={handleShow}>
                <i className="fas fa-plus-circle" />
              </Button>
              <InputUpload
                uploadDataFunction={createListPolitical} 
              />
              </>
          : null
         }
        </Col>
        <Col className="my-3 d-flex justify-content-end" xs={12} lg={6} xl={6}>
          <SearchTextComponet
            textDescription="Buscar..."
            filter={globalFilter}
            setFilter={setGlobalFilter}
          />
        </Col>
      </Row>
      <TableComponent
        page={page}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
      />
      <PaginationTable
        pageIndex={pageIndex}
        pageCount={pageCount}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
      />
      <ModalPoliticalParty open={show} handleClose={handleClose} />
    </>
  );
};

export default PoliticalsTable;
