import '../../assets/styles/spinner.css'
const LoadingSpinner = () => {
  return (
    <div id="loading-wrapper">
    <div id="loading-text">LOADING</div>
    <div id="loading-content"></div>
   </div>
  );
};

export default LoadingSpinner;
