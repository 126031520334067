import { useEffect } from "react";
import AuthLayout from "../../layouts/Dashboard/AuthLayout";
import { Form, Button, InputGroup } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { startResetPassword } from "../../actions/authActions";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";


type Inputs = {
  email: string;
};

const ResetPasswordScreen = () => {
    let history = useHistory();
    const dispatch = useAppDispatch();
    const { loading } = useAppSelector((state) => state.ui);
    const { status } = useAppSelector((state) => state.auth);

    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm<Inputs>();

    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const { email } = data;
        dispatch(startResetPassword(email));
    };

    useEffect(() => {
        if(status){
          history.push('/auth/login');
        }
        // eslint-disable-next-line
    }, [status])

  return (
    <AuthLayout>
      <Form onSubmit={handleSubmit(onSubmit)} role="form">
        <div className="text-center text-muted mb-4">
          <img
            alt="google"
            src={require("../../assets/images/logo.png").default}
            width="100"
          />
        </div>
        <div className="text-center text-muted mb-4">
          <h2>Reestablecer Contraseña</h2>
        </div>
        <Form.Group className="mb-3">
          <InputGroup className="input-group-alternative">
            <InputGroup.Text>
              <i className="fas fa-envelope-square" />
            </InputGroup.Text>
            <Form.Control
              type="email"
              placeholder="Ingresa tu correo"
              {...register("email", {
                required: true,
              })}
            />
          </InputGroup>
          {errors.email && (
            <p className="text-danger">El correo es requerido</p>
          )}
        </Form.Group>
        <Form.Group>
          <p>¿Recordaste tu contraseña? <Link to={'/auth/login'}>Iniciar Sesión</Link></p>
        </Form.Group>
        <div className="text-center">
          <Button
            className="my-4"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Reestablecer
          </Button>
        </div>
      </Form>
    </AuthLayout>
  );
};

export default ResetPasswordScreen;
