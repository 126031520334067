import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword} from "@firebase/auth";
import { auth } from "../firebase/firebase-config";
import { getTypeUser } from "../helpers/firebaseMethods";
import { showAlert } from "../helpers/messagesNotifications";
import { types } from "../types/types";
import { finishLoading, startLoading } from "./globalActions";

export const startLoginEmailPassword = (email: string, password: string) => {
  return (dispatch: any) => {
    dispatch(startLoading());
    signInWithEmailAndPassword(auth, email, password).then(async({user}) => {
       const us = await getTypeUser(user.uid);
       dispatch(login(user.uid , user.displayName , us[0].type_user.value));
       dispatch(finishLoading());
    }).catch((e)=>{
      if(e.code === 'auth/user-not-found'){
        showAlert('Usuario incorrecto',
                  'El usuario no esta registrado en el sistema',
                  'danger'
                  );
      }
      if(e.code === 'auth/wrong-password'){
        showAlert('Contraseña incorrecta',
        'La contraseña es incorrecta',
        'danger'
        );
      }
      dispatch(finishLoading());
    });
  };
};

export const startResetPassword = (email:string) =>{
  return (dispatch:any)=>{
    dispatch(startLoading());
     const auth = getAuth();
     sendPasswordResetEmail(auth,email).then(()=>{
      showAlert('Correcto',
      'Se ha enviado un email a tu correo , revisa tu bandeja de entrada',
      'success'
      );
      dispatch(successResetPassword());
     }).catch(()=>{
          showAlert('Error',
            'El correo que has proporcionado no ha sido registrado en el sistema',
            'danger'
          );
     })
     dispatch(finishLoading());
     }
}

export const successResetPassword = () =>({
  type:types.resetPassword
})

export const startLogout = () =>{
  return async (dispatch:any)=>{
    await auth.signOut();
    dispatch(logout());
  }
}

export const login = (id: string, displayName: string | null , type_user:number) => ({
  type: types.login,
  payload: {
    id,
    displayName,
    type_user
  },
});

export const logout = () =>({
   type: types.logout
});


