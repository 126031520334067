import { addData, deleteData, loadData, updateData } from "../helpers/firebaseMethods"
import { showAlert } from "../helpers/messagesNotifications";
import { types } from "../types/types";

export const startLoadAssignCommissions = () =>{
    return async (dispatch:any)=>{
        try {
            const assign = await loadData('assign-commissions','name');
            dispatch(loadAssignCommissions(assign));
        } catch (error) {
            console.log(error);
        }
    }
}

export const loadAssignCommissions = (assign:any) =>({
    type:types.assignmentLoad,
    payload:assign
});

export const startNewAssignCommissions = (data:any) =>{
   return async (dispatch:any)=>{
       try {
           const {id} = await addData('assign-commissions',data);
           data.id = id;
           dispatch(addAssignCommission(data));
           showAlert('Registro agregado',
                      'El registro ha sido guardado de forma correcta',
                      'success'
                      )
       } catch (error) {
           console.log(error);
       }
   }
}

export const addAssignCommission = (assign:any) =>({
    type:types.assignmentAddNew,
    payload:assign
});

export const startUpdatedAssignCommission = (data:any) =>{
    return async (dispatch:any , getState:any)=>{
        const { assignmentCommissionSelected } = getState().assign;
       try {
       await updateData('assign-commissions' , data);
       data.id = assignmentCommissionSelected.id;
       dispatch(updateAssignCommission(data));
       showAlert('Registro actualizado',
                  'El registro ha sido actualizado de forma correcta',
                  'success'
                 );
       } catch (error) {
        console.log(error);
       }
    }
}

export const activeUpdated = (assignment:any) =>({
   type:types.assignmentActiveUpdated,
   payload:assignment
   
});

export const updateAssignCommission = (data:any) =>({
    type:types.assignmentUpdate,
    payload:data
});



export const startDeletingAssignmentCommission = (id:string) =>{
    return async (dispatch:any)=>{
        try {
            await deleteData('legislatures',id);
            dispatch(deleteAssignCommission(id));
          } catch (error) {
            console.log(error);
          }
    }
}

export const deleteAssignCommission = (id:string) =>({
   type:types.assignmentDelete,
   payload:id
});

export const resetStateAssignment = () =>({
    type:types.assignmentResetState
});
  
