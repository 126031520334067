import { useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import useImportExcel from "../../hooks/useImportExcel";


const InputUpload = ({uploadDataFunction}:any) => {
    const fileRef = useRef<HTMLInputElement>(null);
    const { dataList, loadExcel } = useImportExcel();

    useEffect(() => {
      if (dataList.length > 0) {
        uploadDataFunction(dataList);
    }
    // eslint-disable-next-line
    }, [dataList])

    const selectFile = () => {
        fileRef.current?.click();
    };

    return (
        <>
         <Button variant="success" onClick={selectFile} >
            <i className="fas fa-upload pr-2" /> 
            Importar
          </Button>
          <input
            type="file"
            className="d-none"
            ref={fileRef}
            onChange={loadExcel}
            accept=".xlsx, .xls, .csv"
          />
            
        </>
    );
};

export default InputUpload;