import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import {Col, Row } from "reactstrap";
import { useAppSelector } from "../../hooks/redux-hooks";

interface Props {
  control: any;
  setValue: any;
}
const FormExperienceLegislative = ({
  control,
  setValue,
}: Props) => {
  const { politicianSelected ,politicians } = useAppSelector((state) => state.politicians);

  useEffect(() => {
    if(politicianSelected){
      setValue("experience_legislative",politicianSelected.experience_legislative)
    }else{
      setValue("experience_legislative","")
    }
    // eslint-disable-next-line
  }, [politicianSelected , politicians]);






  return (
    <>
      <Form.Group as={Row} className="mt-3 mb-3">
        <Form.Label>¿Experiencia Legislativa?*:</Form.Label>
        <Col sm={10}>
          <Controller
            name="experience_legislative"
            control={control}
            render={({ field: { value  , onChange} }) => (
              <>
                <Form.Check
                  inline
                  type="radio"
                  label="Si"
                  value="Si"
                  onChange={(e) => onChange(e)}
                  checked={value === "Si" ? true : false}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="No"
                  value="No"
                  onChange={(e) => onChange(e)}
                  checked={value === "No" ? true : false}
                />
              </>
            )}
            rules={{required:true}}
          />
        </Col>
      </Form.Group>
    </>
  );
};

export default FormExperienceLegislative;
