import { useEffect } from "react";
import { Card, Container, Row } from "react-bootstrap";
import {
  startLoadDataSelectPoliticalParties,
  startLoadSelectCommissions,
  startLoadSelectDistricts,
  startLoadSelectLegislatures,
  startLoadSelectAssignmentCommissions
} from "../../actions/dataSelectsAction";
import PoliticiansTable from "../../components/politiciansComponents/politiciansTable";
import { useAppDispatch } from "../../hooks/redux-hooks";
import Dashboard from "../../layouts/Dashboard/Dashboard";
import { startLoadGlobalSettings } from "../../actions/globalSettingsAction";
import useIsMounted from "../../hooks/useIsMounted";

const PoliticianScreen = () => {
  const dispatch = useAppDispatch();
  const isMounted = useIsMounted();

  useEffect(() => {
    document.title = "Observatorio Dashboard-Diputados";
  }, []);

  useEffect(() => {
    if (isMounted()) {
      dispatch(startLoadDataSelectPoliticalParties());
      dispatch(startLoadSelectDistricts());
      dispatch(startLoadSelectLegislatures());
      dispatch(startLoadSelectCommissions());
      dispatch(startLoadGlobalSettings());
      dispatch(startLoadSelectAssignmentCommissions());
    }
    // eslint-disable-next-line
  }, [isMounted]);
 
  
  return (
    <>
      <Dashboard>
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <Card.Header className="border-0">
                  <h3 className="mb-0"> Diputados</h3>
                </Card.Header>
                <PoliticiansTable />
              </Card>
            </div>
          </Row>
        </Container>
      </Dashboard>
    </>
  );
};

export default PoliticianScreen;
