import { useMemo } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
} from "react-table";
import SearchTextComponet from "../uiComponents/searchTextComponet";
import useHandleShowComponents from "../../hooks/useHandleShowComponents";
import ModalCommissions from "./modalCommissions";
import InputUpload from "../uiComponents/inputUpload";
import PaginationTable from "../uiComponents/paginationTable";
import TableComponent from "../uiComponents/tableComponent";
import { COLUMNS_COMMISSIONS } from "../../helpers/columnsTables";
import { activeUpdate, startDeletingCommission, startNewCommissionImportExcel } from "../../actions/commissionActions";
import { Commission } from "../../interfaces/commissionsInterfaces";
import Swal from "sweetalert2";
import { renameDataExcelCommission } from "../../helpers/excelImportRenameData";
import { showAlert } from "../../helpers/messagesNotifications";
import useImportExcel from "../../hooks/useImportExcel";

const CommissionsTable = () => {
  const dispatch = useAppDispatch();
  const { commissions } = useAppSelector((state) => state.commission);

  const columns = useMemo(
    () => [
       ...COLUMNS_COMMISSIONS, 
       {
        Header: "Opciones",
        accessor: "action",
        Cell: ({row}:any) =>(
          <>
            <Button variant="success" 
              onClick={()=>handleUpdateCommission(row.original)}
            >
              <i className="fas fa-edit" />
            </Button>
            <Button variant="danger"
               onClick={()=>handleDeleteCommission(row.original.id)}
            >
              <i className="far fa-trash-alt" />
            </Button>
          </>
        )}],
        // eslint-disable-next-line
        []);
  const data = useMemo(() => commissions, [commissions]);

  const {
    state,
    headerGroups,
    page,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    nextPage,
    previousPage,
    gotoPage,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy, usePagination);
  const { show, handleShow, handleClose } = useHandleShowComponents();
  const { pageIndex, globalFilter } = state;
  const {setDataList } = useImportExcel();

  const handleUpdateCommission = (commission: Commission) => {
    dispatch(activeUpdate(commission));
    handleShow();
  };

  const handleDeleteCommission = (id: string) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¡Una comisión eliminada no se puede recuperar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "No, Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "Eliminado!",
          "La comisión seleccionada ha sido eliminada.",
          "success"
        );
        dispatch(startDeletingCommission(id));
      }
    });
  };

  const createListCommission = (dataList:any) =>{
    dataList.forEach((commission:any)=>{
      const data = renameDataExcelCommission(commission);
      dispatch(startNewCommissionImportExcel(data));
    });
    setDataList([]);
    showAlert(
      "Importación Exitosa",
      "La importación se ha guardado de forma correcta",
      "success"
    );
  }

  return (
    <>
      <Row className="mx-2">
        <Col className="my-3" xs={12} lg={4} xl={6}>
          <Button variant="success" onClick={handleShow}>
            <i className="fas fa-plus-circle" />
          </Button>
          <InputUpload 
            uploadDataFunction={createListCommission}
          />
        </Col>
        <Col className="my-3 d-flex justify-content-end" xs={12} lg={6} xl={6}>
          <SearchTextComponet
            textDescription="Buscar..."
            filter={globalFilter}
            setFilter={setGlobalFilter}
          />
        </Col>
      </Row>
      <TableComponent
        page={page}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
      />
      <PaginationTable
        pageIndex={pageIndex}
        pageCount={pageCount}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
      />
      <ModalCommissions open={show} handleClose={handleClose} />
    </>
  );
};

export default CommissionsTable;
