import { Table } from "react-bootstrap";

type Props = {
  page: any;
  prepareRow: any;
  getTableProps: any;
  headerGroups: any;
  getTableBodyProps: any;
};

const TableComponent = ({
  page,
  prepareRow,
  getTableProps,
  headerGroups,
  getTableBodyProps,
}: Props) => {
  return (
    <Table
      className="align-items-center table-flush"
      responsive
      {...getTableProps()}
    >
      <thead className="thead-light">
        {headerGroups.map((headerGroup: any) => (
          <tr className="text-center" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render("Header")}
                <span className="pl-2">
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <i className="fas fa-sort-amount-down" />
                    ) : (
                      <i className="fas fa-sort-amount-up" />
                    )
                  ) : <i className="fas fa-angle-down" />}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row: any) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell: any) => {
                return (
                  <td align="center" {...cell.getCellProps()}>
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default TableComponent;
