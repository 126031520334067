import { useEffect } from "react";
import Dashboard from "../../layouts/Dashboard/Dashboard";
import useIsMounted from "../../hooks/useIsMounted";
import { Card, Container, Row} from "react-bootstrap";
import RankingsTable from "../../components/rankingComponents/RankingsTable";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { startLoadRankingPoliticians } from "../../actions/rankingActions";
import { startLoadSelectCommissions, startLoadSelectPoliticians } from "../../actions/dataSelectsAction";
import { startLoadGlobalSettings } from "../../actions/globalSettingsAction";


const LegislativeObservatoryScreen = () => {
    const dispatch = useAppDispatch();
    const { update } = useAppSelector((state)=>state.ranking);
    const isMounted = useIsMounted();

    useEffect(() => {
      document.title = "Observatorio Dashboard-Ranking";
    }, []);

    useEffect(() => {
      if (isMounted()) {
        dispatch(startLoadSelectPoliticians());
        dispatch(startLoadGlobalSettings());
       }
        // eslint-disable-next-line
    }, [update , isMounted]);

    useEffect(() => {
      if (isMounted()) {
      dispatch(startLoadRankingPoliticians());
      dispatch(startLoadSelectCommissions());
      }
      // eslint-disable-next-line
    }, [update , isMounted]);
    
    return (
     <Dashboard>
       <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <Card.Header className="border-0">
                  <h3 className="mb-0">Ranking</h3>
                </Card.Header>
                <RankingsTable />
              </Card>
            </div>
          </Row>
        </Container> 
     </Dashboard>
       
    );
};

export default LegislativeObservatoryScreen;