import { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { startLoadPoliticalsParties } from "../../actions/piliticalsPartiesAction";
import { startLoadPoliticians } from "../../actions/politiciansAction";
import AdminFooter from "../../components/dashboardComponents/AdminFooter";
import AdminNavbar from "../../components/dashboardComponents/AdminNavbar";
import AdminSidebar from "../../components/dashboardComponents/AdminSidebar";
import CardInfoComponent from "../../components/uiComponents/cardInfoComponent";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";

const routes = [
  {
    path: "/",
    name: "Ranking",
    icon: "fas fa-clipboard-list text-primary",
  },
  {
    path: "/global-settings",
    name: "Configuración Global",
    icon: "fas fa-cog text-info",
  },
  {
    path: "/commissions",
    name: "Comisiones",
    icon: "fas fa-book-reader text-pink",
  },
  {
    path: "/assignment-commissions",
    name: "Cargos en comisiones",
    icon: "fas fa-users text-info",
  },
  {
    path: "/districts",
    name: "Distritos",
    icon: "fas fa-map-marked-alt text-orange",
  },
  {
    path: "/politicians",
    name: "Diputados",
    icon: "fas fa-users text-info",
  },
  {
    path: "/legislatures",
    name: "Legislaturas",
    icon: "fas fa-calendar-week text-yellow",
  },
  {
    path: "/political-parties",
    name: "Partidos Políticos",
    icon: "fas fa-address-card text-red",
  },
  {
    path: "/users",
    name: "Usuarios",
    icon: "fas fa-user-tie text-info",
  },
];
const Dashboard = ({ children }: any) => {
  const dispatch = useAppDispatch();
  const { politicians } = useAppSelector((state) => state.politicians);
  const { politicals } = useAppSelector((state) => state.political);

  const mr = politicians.filter(
    (politician: any) => politician.election === "MR"
  );
  const rp = politicians.filter(
    (politician: any) => politician.election === "RP"
  );

  useEffect(() => {
    dispatch(startLoadPoliticians());
    dispatch(startLoadPoliticalsParties());
    // eslint-disable-next-line
  }, []);


  return (
    <>
      <AdminSidebar routes={routes} />
      <div className="main-content">
        <AdminNavbar />
        <div
          className="header pb-9 pt-5 pt-lg-8 d-flex align-items-center"
          style={{
            minHeight: "400px",
            backgroundImage:
              "url(" +
              require("../../assets/images/LegislaturadelEdomex.jpg").default +
              ")",
            backgroundSize: "cover",
            backgroundPosition: "center top",
          }}
        >
          {/* Mask */}
          <span className="mask bg-gradient-default opacity-8" />
          {/* Header container */}
          <Container className="d-flex align-items-center " fluid>
            <Row>
              <Col lg="12" md="10">
                <h1 className="display-2 text-white text-center">
                  Observatorio Legislativo
                </h1>
              </Col>
              <CardInfoComponent
                name="No. de Diputados"
                icon="fas fa-users"
                num={politicians.length}
                color="bg-warning"
              />
              <CardInfoComponent
                name="Partidos Políticos"
                icon="fas fa-jedi"
                num={politicals.length}
                color="bg-danger"
              />
              <CardInfoComponent
                name="Mayoría Relativa"
                icon="fas fa-balance-scale-right"
                num={mr.length}
                color="bg-yellow"
              />
              <CardInfoComponent
                name="Rep. Proporcional"
                icon="fas fa-balance-scale-left"
                num={rp.length}
                color="bg-info"
              />
            </Row>
          </Container>
        </div>
        {children}
        <Container>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Dashboard;
