import { useEffect } from "react";
import { Card, Container, Row} from "react-bootstrap";
import { startLoadDistricts } from "../../actions/districtsActions";
import DistrictsTable from "../../components/districtsComponents/districtsTable";
import { useAppDispatch } from "../../hooks/redux-hooks";
import Dashboard from "../../layouts/Dashboard/Dashboard";
import useIsMounted from "../../hooks/useIsMounted";


const DistrictsScreen = () => {
  const dispatch = useAppDispatch();
  const isMounted = useIsMounted();
  useEffect(() => {
    document.title = "Distritos";
  }, []);

  useEffect(() => {
    if(isMounted()){
    dispatch(startLoadDistricts());
    }
    // eslint-disable-next-line
  }, [isMounted]);

  return (
    <Dashboard>
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <Card.Header className="border-0">
                <h3 className="mb-0"> Distritos</h3>
              </Card.Header>
              <Container>
              <DistrictsTable />
              </Container>
            </Card>
          </div>
        </Row>
      </Container>
    </Dashboard>
  );
};

export default DistrictsScreen;
