import { useMemo } from "react";
import { Button, Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useAppSelector, useAppDispatch } from "../../hooks/redux-hooks";
import { District } from "../../interfaces/districtsInterfaces";
import ModalDistricts from "./modalDistricts";
import ModalSections from "./modalSections";
import InputUpload from "../uiComponents/inputUpload";
import SearchTextComponet from "../uiComponents/searchTextComponet";
import TableComponent from "../uiComponents/tableComponent";
import PaginationTable from "../uiComponents/paginationTable";
import useHandleShowComponents from "../../hooks/useHandleShowComponents";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { COLUMNS_DISTRICTS } from "../../helpers/columnsTables";
import {
  activeUpdate,
  showSections,
  startDeleteDistrict,
  startNewDistrictImportExcel,
} from "../../actions/districtsActions";
import Swal from "sweetalert2";
import { renameDataExcelDistrict } from "../../helpers/excelImportRenameData";
import { showAlert } from "../../helpers/messagesNotifications";
import useImportExcel from "../../hooks/useImportExcel";

const DistrictsTable = () => {
  const dispatch = useAppDispatch();
  const { districts } = useAppSelector((state) => state.district);
  const { type_user } = useAppSelector((state) => state.auth);
  const { setDataList } = useImportExcel();

  const createListDistricts = (dataList: any) => {
    dataList.forEach((district: any) => {
      const data = renameDataExcelDistrict(district);
      dispatch(startNewDistrictImportExcel(data));
    });
    setDataList([]);
    showAlert(
      "Importación Exitosa",
      "La importación se ha guardado de forma correcta",
      "success"
    );
  };

  const columns = useMemo(
    () => [
      ...COLUMNS_DISTRICTS,
      {
        Header: "Opciones",
        accessor: "action",
        Cell: ({ row }: any) => (
          <>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Ver Secciones</Tooltip>}
            >
              <Button
                variant="info"
                onClick={() => showModalSections(row.original)}
              >
                <i className="fas fa-eye" />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Actualizar Distrito</Tooltip>}
            >
              <Button
                variant="success"
                onClick={() => handleUpdateDistrict(row.original)}
              >
                <i className="fas fa-edit" />
              </Button>
            </OverlayTrigger>
            {
              type_user !== 2 ?
            
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Eliminar Distrito</Tooltip>}
            >
              <Button
                variant="danger"
                onClick={() => handleDeleteDistrict(row.original.id)}
              >
                <i className="far fa-trash-alt" />
              </Button>
            </OverlayTrigger>
            : null
            }
          </>
        ),
      }
    ],// eslint-disable-next-line
    []
  );
  const data = useMemo(() => districts, [districts]);

  const {
    state,
    headerGroups,
    page,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    nextPage,
    previousPage,
    gotoPage,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy, usePagination);
  const {
    show: showSection,
    handleShow: handleShowSections,
    handleClose: handleCloseSections,
  } = useHandleShowComponents();
  const {
    show: showForm,
    handleShow: handleShowForm,
    handleClose: handleCloseForm,
  } = useHandleShowComponents();
  const { pageIndex, globalFilter } = state;

  const handleUpdateDistrict = (district: District) => {
    dispatch(activeUpdate(district));
    handleShowForm();
  };

  const showModalSections = (district: District) => {
    dispatch(showSections(district.sections));
    handleShowSections();
  };

  const handleDeleteDistrict = (id: string) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¡Un distrito eliminado no se puede recuperar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "No, Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "Eliminado!",
          "El distrito seleccionado ha sido eliminado.",
          "success"
        );
        dispatch(startDeleteDistrict(id));
      }
    });
  };

  return (
    <>
      <Row className="mx-2">
        <Col className="my-3" xs={12} lg={4} xl={6}>

          {
            type_user !== 2 ?
            <>
            <Button variant="success" onClick={handleShowForm}>
              <i className="fas fa-plus-circle" />
            </Button>
            <InputUpload uploadDataFunction={createListDistricts} />
            </>
            : null
          }
        </Col>
        <Col className="my-3 d-flex justify-content-end" xs={12} lg={6} xl={6}>
          <SearchTextComponet
            textDescription="Buscar..."
            filter={globalFilter}
            setFilter={setGlobalFilter}
          />
        </Col>
      </Row>
      <TableComponent
        page={page}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
      />
      <PaginationTable
        pageIndex={pageIndex}
        pageCount={pageCount}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
      />
      <ModalDistricts open={showForm} handleClose={handleCloseForm} />
      <ModalSections open={showSection} handleClose={handleCloseSections} />
    </>
  );
};

export default DistrictsTable;
