import { useMemo } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { activeUpdateUser } from "../../actions/usersActions";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import useHandleShowComponents from "../../hooks/useHandleShowComponents";
import PaginationTable from "../uiComponents/paginationTable";
import SearchTextComponet from "../uiComponents/searchTextComponet";
import TableComponent from "../uiComponents/tableComponent";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { COLUMNS_USERS } from "../../helpers/columnsTables";
import ModalUser from "./modalUser";

const UsersTable = () => {
  const dispatch = useAppDispatch();
  const {type_user} = useAppSelector((state)=>state.auth)
  const { users } = useAppSelector((state) => state.users);
  const { show, handleShow, handleClose } = useHandleShowComponents();
  const columns = useMemo(
    () => [
      ...COLUMNS_USERS,
      {
        Header: "Opciones",
        accessor: "action",
        Cell: ({ row }: any) => (
          <>
          {
           type_user === 0 ?
          <Button
            variant="success"
            onClick={() => handleUpdateUser(row.original)}
          >
            <i className="fas fa-edit" />
          </Button>
          : 'Sin opciones disponibles'
          }
          </>
        ),
      },
    ],
    // eslint-disable-next-line
    []
  );
  const data = useMemo(() => users, [users]);
  const {
    state,
    headerGroups,
    page,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    nextPage,
    previousPage,
    gotoPage,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy, usePagination);
  const { pageIndex, globalFilter } = state;

  const handleUpdateUser = (user: any) => {
    dispatch(activeUpdateUser(user));
    handleShow();
  };

  return (
    <>
      <Row className="mx-2">
        <Col className="my-3" xs={12} lg={4} xl={6}>
          {
            type_user === 0 ?
            <Button variant="success" onClick={handleShow}>
            <i className="fas fa-plus-circle" />
            </Button>
          : null
          }

        </Col>
        <Col className="my-3 d-flex justify-content-end" xs={12} lg={6} xl={6}>
          <SearchTextComponet
            textDescription="Buscar..."
            filter={globalFilter}
            setFilter={setGlobalFilter}
          />
        </Col>
      </Row>
      <TableComponent
        page={page}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
      />
      <PaginationTable
        pageIndex={pageIndex}
        pageCount={pageCount}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
      />
      <ModalUser open={show} handleClose={handleClose} />
    </>
  );
};

export default UsersTable;
