import { OverlayTrigger , Tooltip , Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { activeUpdatedSetting, startDeleteGlobalSetting } from "../../actions/globalSettingsAction";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { GlobalSettings } from "../../interfaces/globalSettingsInterface";
import GlobalSettingsFormModal from './globalSettingsFormModal';
import useHandleShowComponents from "../../hooks/useHandleShowComponents";
interface Props {
  setting: GlobalSettings;
}

const GlobalSettingTable = ({ setting }: Props) => {
  const {
    id,
    plenarySessions,
    legislature,
    sessions_per_period,

  } = setting;

  const dispatch = useAppDispatch();
  const { show, handleShow, handleClose } = useHandleShowComponents();
  const { type_user } = useAppSelector((state) => state.auth);

  const handleUpdateGlobalSetting = (setting:GlobalSettings) =>{
    dispatch(activeUpdatedSetting(setting))
    handleShow();
  }

  const handleDeleteGlobalSetting = (id:string) =>{
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¡Una configuración eliminada no se puede recuperar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "No, Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "Eliminado!",
          "La comisión seleccionada ha sido eliminada.",
          "success"
        );
        dispatch(startDeleteGlobalSetting(id));
      }
    });

  }
  return (
    <>
    <tr>
      <td align="center">{legislature?.value || 'N/E'}</td>
      <td align="center">{plenarySessions}</td>
      <td align="center">{sessions_per_period}</td>
      <td align="center">
      {
            type_user !== 2 ?
            <>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Editar configuración</Tooltip>}
          >
            <Button
              variant="success"
              onClick={() => handleUpdateGlobalSetting(setting)}
            >
              <i className="fas fa-edit" />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>Eliminar configuración</Tooltip>}
          >
            <Button variant="danger" onClick={() => handleDeleteGlobalSetting(id)}>
              <i className="far fa-trash-alt" />
            </Button>
          </OverlayTrigger>
          </>
          : <p>Sin opciones disponibles</p>
         }
        </td>
    </tr>
    <GlobalSettingsFormModal
      open={show} 
      handleClose={handleClose}
    />
    </>
  );
};

export default GlobalSettingTable;
