import { useEffect , useRef } from "react";
import { useGetImage } from "../hooks/useGetImage";

export const GetImageStorage = (path:string) =>{
  const { photo, getImagePath } = useGetImage();
  let isMounted = useRef(false);

  useEffect(() => {
    isMounted.current=true;
    return ()=>{
      isMounted.current=false;
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if(isMounted.current){
       getImagePath(path || 'produccion/political-parties/default-image.png');
      }
    }, 1000);
    // eslint-disable-next-line
  }, [path])

  return photo;
}

export const COLUMNS_COMMISSIONS = [
  {
    Header: "ID",
    accessor: "commission_id"
  },
  {
    Header: "Nombre",
    accessor: "name",
    Cell:({value}:any)=><span className="d-inline-block text-truncate" style={{maxWidth:'200px'}}>{value}</span>
  },
  {
    Header: "No. de Sesiones",
    accessor: "no_sessions_commission",
  },
  {
    Header: "Descripción",
    accessor: "description",
    Cell:({value}:any)=><span className="d-inline-block text-truncate" style={{maxWidth:'200px'}}>{value}</span>
  },
];

export const COLUMNS_LEGISLATURES = [
  {
    Header: "ID",
    accessor: "identificador",
    Cell: ({ row }: any) => <span>{Number(row.id) + 1}</span>,
  },
  {
    Header: "Nombre",
    accessor: "name",
  },
];

export const COLUMNS_DISTRICTS = [
  {
    Header: "ID",
    accessor: "id_district",
  },
  {
    Header: "Número en Romano",
    accessor: "num_roman",
  },
  {
    Header: "Nombre del Distrito",
    accessor: "name",
  },
  {
    Header: "Número de Representados",
    accessor: "nominal",
  },
];

export const COLUMNS_POLITICAL_PARTIES = [
  {
    Header: "ID",
    accessor: "identificador",
    Cell: ({ row }: any) => <span>{Number(row.id) + 1}</span>,
  },
  {
    Header: "Imagen",
    accessor: "image_path",
    Cell: ({ value }: any) => {

      return <img width="70" height="auto" src={GetImageStorage(value)} alt={value} />;
    },
  },
  {
    Header: "Nombre",
    accessor: "name",
  },
  {
    Header: "Abreviación",
    accessor: "abbreviation",
  },
  {
    Header: "Año de Fundación",
    accessor: "foundation_year",
  },
  {
    Header: "Ideología",
    accessor: "ideology",
  },
];

export const COLUMNS_POLITICIANS = [
  {
    Header: "ID",
    accessor: "identificador",
    Cell: ({ row }: any) => <span>{Number(row.id) + 1}</span>,
  },
  {
    Header: "Imagen",
    accessor: "image_path",
    Cell: ({ value }: any) => {
     return <img width="70" height="auto" src={GetImageStorage(value)} alt="politician-img" />;
    },
  },
  {
    Header: "Nombre",
    accessor: "name",
  },
  {
    Header: "Partido Politíco",
    accessor: "political_party.value",
  },
  {
    Header: "Comisión",
    accessor: "commission.label",
    Cell:({value}:any) => ( <span>{value?value:'N/E'}</span> )
  },
  {
    Header: "Elegido Por ",
    accessor: "election",
  },
  {
    Header: "Distrito",
    accessor: "district.label",
  },

];

export const COLUMNS_RANKING = [
  {
    Header: "Posición",
    accessor: "identificador",
    Cell: ({ row }: any) => <span>{Number(row.id) + 1}</span>,
  },
  {
    Header: "Imagen",
    accessor: "image_path",
    Cell: ({ value }: any) => {
      return <img width="70" height="auto" src={GetImageStorage(value)} alt="politician-img" />;
    },
  },
  {
    Header: "Nombre",
    accessor: "name",
  },
  {
    Header: "Partido Político",
    accessor: "political_party.value",
  },
  {
    Header: "Asistencia",
    accessor: "percentile_Indicators.assistances",
  },
  {
    Header: "Asistencia a Comisiones",
    accessor: "percentile_Indicators.commisions_assistances",
  },
  {
    Header: "Participación en Pleno",
    accessor: "percentile_Indicators.full_participation",
  },
  {
    Header: "Iniciativas Presentadas",
    accessor: "percentile_Indicators.initiatives_presented",
  },
  {
    Header: "Transparencia",
    accessor: "percentile_Indicators.transparency"
  },
  {
    Header: "Iniciativas Aprobadas",
    accessor: "percentile_Indicators.initiatives_approved",
  },
  {
    Header: "Total",
    accessor: "percentile_Indicators.total",
  },
];

export const COLUMNS_USERS = [
  {
    Header:"Nombre",
    accessor:"displayName"
  },
  {
    Header:"Correo",
    accessor:"email"
  },
  {
    Header:"Tipo de Usuario",
    accessor:"type_user.label"
  }
];

export const COLUMNS_COMMISSION_IN_COMMISSIONS = [
  {
    Header:"ID",
    accessor:"assignmentID"
  },
  {
    Header:"Cargo",
    accessor:"name"
  }
]
