import { types } from "../types/types";

interface State{
    id:string,
    name:string|null
    type_user:number;
    status:boolean;
}

const initialState:State = {
    id: '',
    name: '',
    type_user:0,
    status:false
}

export const authReducer = (state=initialState , action:any) =>{

    switch (action.type) {
        case types.login:
            return {
                 id:action.payload.id,
                 name:action.payload.displayName,
                 type_user:action.payload.type_user
            }
        case types.logout:
            return{
               id:'',
               name:'',
               type_user:0
            }
        case types.resetPassword:
            return{
                id: '',
                name: '',
                type_user:0,
                status:true
            }
    
        default:
            return state
    }

}