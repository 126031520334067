import { useState } from "react";
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../hooks/redux-hooks";
import { startLogout } from "../../actions/authActions";

interface Props {
  routes: Array<Route>;
}

interface Route {
  path: string;
  icon: string;
  name: string;
}

const AdminSidebar = ({ routes }: Props) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const dispatch = useAppDispatch();
    
    const handleLogout = () =>{
        dispatch(startLogout());
    }

  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen);
  };


  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes: Array<Route>) => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <Link className="nav-link" to={prop.path}>
            <i className={prop.icon} />
            {prop.name}
          </Link>
        </NavItem>
      );
    });
  };
  return (
    <Navbar
    className="navbar-vertical fixed-left navbar-light bg-white"
    expand="md"
    id="sidenav-main"
  >
    <Container fluid>
      {/* Toggler */}
      <button
        className="navbar-toggler"
        type="button"
        onClick={toggleCollapse}
      >
        <span className="navbar-toggler-icon" />
      </button>
      {/* Brand */}
        <NavbarBrand className="pt-0">
          <img
            alt={'...'}
            className="navbar-brand-img"
            src={require("../../assets/images/logo_monitoreo2.png").default}
          />
        </NavbarBrand>
      {/* User */}
      <Nav className="align-items-center d-md-none">
        <UncontrolledDropdown nav>
          <DropdownToggle nav>
            <Media className="align-items-center">
              <span className="avatar avatar-sm rounded-circle">
                <img
                  alt="..."
                  src={
                    require("../../assets/images/logo_observatorio.png").default
                    
                  }
                />
              </span>
            </Media>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" end>
            <DropdownItem href="#pablo" onClick={() => handleLogout()}>
            <i className="fas fa-sign-out-alt"/>
            <span>Cerrar Sesión</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
      {/* Collapse */}
      <Collapse navbar isOpen={collapseOpen}>
        {/* Collapse header */}
        <div className="navbar-collapse-header d-md-none">
          <Row>
  
              <Col className="collapse-brand" xs="6">
                  <Link to={'/'}>
                    <img alt='logo' src={require("../../assets/images/logo_monitoreo2.png").default} />
                  </Link>
            
              </Col>
            <Col className="collapse-close" xs="6">
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleCollapse}
              >
                <span />
                <span />
              </button>
            </Col>
          </Row>
        </div>
        {/* Form */}
        <Form className="mt-4 mb-3 d-md-none">
          <InputGroup className="input-group-rounded input-group-merge">
            <Input
              aria-label="Search"
              className="form-control-rounded form-control-prepended"
              placeholder="Search"
              type="search"
            />
              <InputGroupText>
                <span className="fa fa-search" />
              </InputGroupText>
          </InputGroup>
        </Form>
        {/* Navigation */}
        <Nav navbar>{createLinks(routes)}</Nav>
      </Collapse>
    </Container>
  </Navbar>
  );
};

export default AdminSidebar;
