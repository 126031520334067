import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { login } from "../actions/authActions";
import LoadingSpinner from "../components/uiComponents/loadingSpinner";
import { auth } from "../firebase/firebase-config";
import { getTypeUser } from "../helpers/firebaseMethods";
import { useAppDispatch } from "../hooks/redux-hooks";
import CommissionScreen from "../views/commision/commissionScreen";
import AssingmentCommissionsScreen from "../views/assignmentCommissions/assignmentCommissionsScreen";
import DistrictsScreen from "../views/districts/districtsScreen";
import GlobalSettingScreen from "../views/global-settings/globalSettingScreen";
import LegislativeObservatoryScreen from "../views/home/legislativeObservatoryScreen";
import LegislatureScreen from "../views/legilature/legislatureScreen";
import PoliticalPartiesScreen from "../views/politicalParties/politicalPartiesScreen";
import PoliticianScreen from "../views/politicians/politicianScreen";
import PoliticianShowScreen from "../views/politicians/politicianShowScreen";
import UsersScreen from "../views/users/usersScreen";
import AuthRouter from "./AuthRouter";
import { PrivateRoute } from "./PrivateRoutes";
import { PublicRoute } from "./PublicRoutes";

const AppRouter = () => {
  const dispatch = useAppDispatch();
  const [loadingscreen, setLoading] = useState<boolean>(true);
  const [isLoggedIn, setIsLoggedIn] = useState<Boolean>(false);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user?.uid) {
        const us = await getTypeUser(user.uid);
        dispatch(login(user.uid, user.displayName , us[0].type_user.value));
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
      setLoading(false);
    });
  }, [dispatch]);

  if (loadingscreen) {
    return <LoadingSpinner/>
  }

  return (
    <Router>
      <Switch>
        <PublicRoute
          path="/auth"
          isAuthenticate={isLoggedIn}
          component={AuthRouter}
        />
        <PrivateRoute
          exact
          path="/"
          isAuthenticate={isLoggedIn}
          component={LegislativeObservatoryScreen}
        />
        <PrivateRoute
          exact
          path="/legislatures"
          isAuthenticate={isLoggedIn}
          component={LegislatureScreen}
        />
        <PrivateRoute
          exact
          path="/commissions"
          isAuthenticate={isLoggedIn}
          component={CommissionScreen}
        />
        <PrivateRoute
          exact
          path="/assignment-commissions"
          isAuthenticate={isLoggedIn}
          component={AssingmentCommissionsScreen}
        />
        <PrivateRoute
          exact
          path="/political-parties"
          isAuthenticate={isLoggedIn}
          component={PoliticalPartiesScreen}
        />
        <PrivateRoute
          exact
          path="/districts"
          isAuthenticate={isLoggedIn}
          component={DistrictsScreen}
        />
        <PrivateRoute
           exact
           path="/politicians"
           isAuthenticate={isLoggedIn}
           component={PoliticianScreen}
        />
        <PrivateRoute
           exact
           path="/users"
           isAuthenticate={isLoggedIn}
           component={UsersScreen}
        />
         <PrivateRoute
           exact
           path="/politician-show/:id"
           isAuthenticate={isLoggedIn}
           component={PoliticianShowScreen}
        />
        
        <PrivateRoute
           exact
           path="/global-settings"
           isAuthenticate={isLoggedIn}
           component={GlobalSettingScreen}
        />
        <Redirect to="/auth/login" />
      </Switch>
    </Router>
  );
};

export default AppRouter;
