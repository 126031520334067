import { useMemo } from "react";
import {
  Col,
  Row,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Swal from "sweetalert2";
import {
  activeUpdatePolitician,
  startAddNewPoliticianExcel,
  startDeletePolitician,
} from "../../actions/politiciansAction";
import { COLUMNS_POLITICIANS } from "../../helpers/columnsTables";
import { showAlert } from "../../helpers/messagesNotifications";
import { convertDataExcelToPoliticiansData } from "../../helpers/rankingHelpers";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import useHandleShowComponents from "../../hooks/useHandleShowComponents";
import useImportExcel from "../../hooks/useImportExcel";
import { Politician } from "../../interfaces/politiciansInterface";
import InputUpload from "../uiComponents/inputUpload";
import PaginationTable from "../uiComponents/paginationTable";
import SearchTextComponet from "../uiComponents/searchTextComponet";
import TableComponent from "../uiComponents/tableComponent";
import ModalPoliticians from "./modalPoliticians";

const PoliticiansTable = () => {
  const dispatch = useAppDispatch();
  const { politicians } = useAppSelector((state) => state.politicians);
  const { type_user } = useAppSelector((state) => state.auth);
  const { setDataList } = useImportExcel();
  const columns = useMemo(
    () => [
      ...COLUMNS_POLITICIANS,
      {
        Header: "Opciones",
        accessor: "action",
        Cell: ({ row }: any) => (
          <>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Ver Más ...</Tooltip>}
            >
              <Link to={`/politician-show/${row.original.id}`} className="mr-3">
                <Button variant="warning">
                  <i className="fas fa-eye" />
                </Button>
              </Link>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Editar Diputado</Tooltip>}
            >
              <Button
                variant="success"
                onClick={() => handleUpdatePolitician(row.original)}
              >
                <i className="fas fa-edit" />
              </Button>
            </OverlayTrigger>
            {
              type_user !==2 ?
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Eliminar Diputado</Tooltip>}
            >
              <Button
                variant="danger"
                onClick={() => handleDelete(row.original.id)}
              >
                <i className="far fa-trash-alt" />
              </Button>
            </OverlayTrigger>
            : null
            }
          </>
        ),
      }
      // eslint-disable-next-line
    ],[]);

  const data = useMemo(() => politicians, [politicians]);

  const {
    state,
    headerGroups,
    page,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    nextPage,
    previousPage,
    gotoPage,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy, usePagination);
  const { show, handleShow, handleClose } = useHandleShowComponents();
  const { pageIndex, globalFilter } = state;

  const handleUpdatePolitician = (politician: Politician) => {
    dispatch(activeUpdatePolitician(politician));
    handleShow();
  };

  const handleDelete = (id: string) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "¡Un político eliminado no se puede recuperar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "No, Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "Eliminado!",
          "El político seleccionado ha sido eliminado.",
          "success"
        );
        dispatch(startDeletePolitician(id));
      }
    });
  };

  const createListPolitician = (dataList:any) => {
    dataList.forEach((politician: any) => {
      const data = convertDataExcelToPoliticiansData(politician);
      dispatch(startAddNewPoliticianExcel(data));
    });
    setDataList([]);
    showAlert(
      "Importación Exitosa",
      "La importación se ha guardado de forma correcta",
      "success"
    );
  };

  return (
    <>
      <Row className="mx-2">
        <Col className="my-3" xs={12} lg={4} xl={6}>
          {type_user !== 2 ?
          <>
          <Button variant="success" onClick={handleShow}>
            <i className="fas fa-plus-circle" />
          </Button>
          <InputUpload 
            uploadDataFunction={createListPolitician} 
          />
          </>
          :null
         }
        </Col>
        <Col className="my-3 d-flex justify-content-end" xs={12} lg={6} xl={6}>
          <SearchTextComponet
            textDescription="Buscar..."
            filter={globalFilter}
            setFilter={setGlobalFilter}
          />
        </Col>
      </Row>
      <TableComponent
        page={page}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
      />
      <PaginationTable
        pageIndex={pageIndex}
        pageCount={pageCount}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
      />
      <ModalPoliticians open={show} handleClose={handleClose} />
      {/* <pre>
        <code>
           {JSON.stringify(politicians , null , 2)}
        </code>
      </pre> */}
     
    </>
  );
};

export default PoliticiansTable;
