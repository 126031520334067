import { createStore , combineReducers , compose , applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { assignmentCommissionReducer } from '../reducers/assignmentCommissionReducer';
import { authReducer } from '../reducers/authReducer';
import { commissionsReducer } from '../reducers/commissionReducer';
import { dataSelectsReducer } from '../reducers/dataSelectsReducer';
import { districtReducer } from '../reducers/districtReducer';
import { globalSettingsReducer } from '../reducers/globalSettingsReducer';
import { legislatureReducer } from '../reducers/legislatureReducer';
import { politicalPartieReducer } from '../reducers/politicalPartiesReducer';
import { politiciansReducer} from '../reducers/politiciansReducer';
import { rankingReducer } from '../reducers/rankingReducer';
import { uiReducer } from '../reducers/uiReducer';
import { usersReducer } from '../reducers/usersReducer';

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducers = combineReducers({
    auth: authReducer,
    ui: uiReducer,
    legislature: legislatureReducer,
    commission: commissionsReducer,
    political: politicalPartieReducer,
    district: districtReducer,
    dataSelects: dataSelectsReducer,
    politicians: politiciansReducer,
    global_settings: globalSettingsReducer,
    ranking: rankingReducer,
    users: usersReducer,
    assignment:assignmentCommissionReducer,
});


export const store = createStore(
    reducers,
    composeEnhancers(
    applyMiddleware(thunk)
    )
  
);
export type AppState = ReturnType<typeof reducers>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch