import { useEffect } from "react";
import { Modal, Button, Form, Row, Col  } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {
  resetFormPolitical,
  startNewPoliticalParty,
  startUpdatedPoliticalParty,
} from "../../actions/piliticalsPartiesAction";
import PropTypes from "prop-types";
import useUploadImage from "../../hooks/useUploadImage";
import ImageUploader from "react-images-upload";


interface Props {
  open: boolean;
  handleClose: () => void;
}
type Inputs = {
  id: string;
  image_path: string;
  name: string;
  abbreviation: string;
  foundation_year: string;
  ideology: string;
  accountFB: string;
  accountTW: string;
  accountINS: string;
};

const ModalPoliticalParty = ({ open, handleClose }: Props) => {
  const dispatch = useAppDispatch();
  const { update, politicalSelected , politicals} = useAppSelector(
    (state) => state.political
  );
  const { pictures, onDrop } = useUploadImage();
  const { loading } = useAppSelector((state) => state.ui);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<Inputs>();

  useEffect(() => {
    if(politicalSelected){
      setValue("name",politicalSelected.name, { shouldDirty: true });
      setValue("abbreviation",politicalSelected.abbreviation, { shouldDirty: true });
      setValue("foundation_year", politicalSelected.foundation_year, { shouldDirty: true });
      setValue("ideology",politicalSelected.ideology, { shouldDirty: true });
      setValue("accountFB",politicalSelected.accountFB, { shouldDirty: true });
      setValue("accountTW",politicalSelected.accountTW, { shouldDirty: true });
      setValue("accountINS",politicalSelected.accountINS, { shouldDirty: true });
    }else{
      setValue("name","", { shouldDirty: true })
      setValue("abbreviation","", { shouldDirty: true })
      setValue("foundation_year","", { shouldDirty: true })
      setValue("ideology","", { shouldDirty: true })
      setValue("accountFB","", { shouldDirty: true })
      setValue("accountTW","", { shouldDirty: true })
      setValue("accountINS","", { shouldDirty: true })
    }
    // eslint-disable-next-line
  }, [politicalSelected , politicals])


  const handleResetForm = () =>{
    handleClose();
    dispatch(resetFormPolitical());
  }

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (update) {
      dispatch(startUpdatedPoliticalParty(data, pictures));
    } else {
      dispatch(startNewPoliticalParty(data, pictures));
    }
    handleResetForm();
  };


  return (
    <Modal show={open} onHide={handleResetForm} size="lg">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {update ? "Editar Partido Político" : "Agregar Partido Político"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <Form.Group className="mb-3">
                <ImageUploader
                  withIcon={false}
                  buttonText="Escoge una imagen"
                  onChange={onDrop}
                  imgExtension={[".jpg", ".jpeg", ".png"]}
                  accept="acepta .jpg | .jpeg | .png"
                  maxFileSize={5242880}
                  withPreview
                  singleImage
                  label="Acepta JPEG | JPG | PNG"
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <Form.Group className="mb-3">
                <Form.Label>Nombre Partido Político:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="ej: Partido Acción Nacional"
                  {...register("name", {
                    required: true,
                  })}
                />
                {errors.name && (
                  <p className="text-danger">
                    El nombre del partido político es requerido
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <Form.Group className="mb-3">
                <Form.Label>Abreviación Partido Político:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="ej: PAN"
                  {...register("abbreviation", {
                    required: true,
                  })}

                />
                {errors.abbreviation && (
                  <p className="text-danger">
                    La abreviación del partido político es requerido
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <Form.Group className="mb-3">
                <Form.Label>Página de facebook:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ej: https://www.facebook.com/PartidoAccionNacional"
                  {...register("accountFB", {
                    required: true,
                  })}

                />
                {errors.accountFB && (
                  <p className="text-danger">
                    {errors.accountFB.message}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <Form.Group className="mb-3">
                <Form.Label>Página de Twitter:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ej: https://twitter.com/AccionNacional"
                  {...register("accountTW", {
                    required: true,
                  })}

                />
                {errors.accountTW && (
                  <p className="text-danger">
                    {errors.accountTW.message}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <Form.Group className="mb-3">
                <Form.Label>Página de instagram:</Form.Label>
                <Form.Control
                type="text"
                placeholder="Ej: https://twitter.com/AccionNacional"
                {...register("accountINS", {
                  required: true,
                })}
                />
                {errors.accountINS && (
                  <p className="text-danger">
                    {errors.accountINS.message}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
              <Form.Group className="mb-3">
                <Form.Label>Año de Fundación:</Form.Label>
                <Form.Control
                type="date"
                placeholder="Ej: https://twitter.com/AccionNacional"
                data-date-format="DD-MMMM-YYYY"
                {...register("foundation_year", {
                  required: true,
                })}
                />
                
                {errors.foundation_year && (
                  <p className="text-danger">
                    {errors.foundation_year.message}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <Form.Group className="mb-3">
              <Form.Label>Ideología del partido político:</Form.Label>
              <Form.Control
                as="textarea"
                aria-label="with textare"
                rows={5}
                placeholder="ej: Centro Derecha"
                {...register("ideology", {
                  required: true,
                })}
              />
              {errors.ideology && (
                <p className="text-danger">
                  La ideología del partido político es requerido
                </p>
              )}
            </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleResetForm}>
            Cerrar
          </Button>
          <Button variant="primary" type="submit" disabled={loading}>
            Guardar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

ModalPoliticalParty.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ModalPoliticalParty;
