import { FC , useEffect } from "react";
import { Container, Row, Col , Card } from "react-bootstrap";

const AuthLayout: FC = ({ children }) => {

  useEffect(() => {
    document.title = "Observatorio Dashboard - Login";
  }, []);


  useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);

  return (
    <div className="main-content">
      <div className="header bg-gradient-info py-8 py-lg-8">
        <Container>
          <div className="header-body text-center mb-3">
            <Row className="justify-content-center">
              <Col lg="5" md="6">
                <h1 className="text-white">
                  Bienvenido a Observatorio Legislativo
                </h1>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="separator separator-bottom separator-skew zindex-100">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-default" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </div>
      <Container className="mt-n8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary shadow border-0">
              <Card.Body>
                {children}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      
    </div>
  );
};

export default AuthLayout;
