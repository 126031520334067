import { useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Controller, SubmitHandler, useForm  } from "react-hook-form";
import { useAppSelector , useAppDispatch } from "../../hooks/redux-hooks";
import Select from 'react-select';
import { startRegisterUsers , startUpdateUser } from "../../actions/usersActions";


type Props ={
  open:boolean;
  handleClose: () => void;
}

type Inputs = {
  id:string;
  user_id:string;
  displayName:string;
  email:string;
  password:string;
  type_user:{label:string, value:number};
};

const ModalUser = ({open , handleClose}:Props) => {
    const dispatch = useAppDispatch();
    const {update , userSelected} = useAppSelector((state)=>state.users);
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue
        } = useForm<Inputs>({ defaultValues: userSelected });
    
    useEffect(() => {
      if(userSelected){
       setValue("type_user", userSelected?.type_user);
       setValue("displayName", userSelected?.displayName);
       setValue("email", userSelected?.email);
       setValue("password", userSelected?.password || '');
      }else{
        setValue("type_user",{label:"" , value:0});
        setValue("displayName", "")
        setValue("email","")
        setValue("password","")
      }
      // eslint-disable-next-line
    }, [userSelected])
    
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        if(update){
            dispatch(startUpdateUser(data));
        }else{
            dispatch(startRegisterUsers(data));
        }
        handleClose();
    };
    return (
    <Modal show={open} onHide={handleClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title> {update ? 'Editar Usuario' : 'Agregar Usuario'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            !update ? 
            <>
            <Form.Group className="mb-3">
            <Form.Label>Nombre de Usuario:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ej: Jose María"
              {...register("displayName", {
                required: true,
              })}
            />
            {errors.displayName && <p className="text-danger">El nombre de usuario es un campo requerido.</p>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Ingresa un correo:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ej: correo@correo.com"
              {...register("email", {
                required: true,
              })}
            />
            {errors.email && <p className="text-danger">El correo es un campo requerido.</p>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Ingresa un contraseña:</Form.Label>
            <Form.Control
              type="password"
              placeholder="Ingresa una contraseña"
              {...register("password", {
                required: false,
              })}
            />
            {errors.password && <p className="text-danger">La contraseña es un campo requerido.</p>}
          </Form.Group>
          </>
          : null
          }
         
          <Form.Group className="mb-3">
            <Form.Label>Selecciona un tipo de usuario:</Form.Label>
             <Controller
                name="type_user"
                control={control}
                render={({field})=>(
                  <Select
                   {...field}
                   options={[
                       {label:'Administrador',value:0},
                       {label:'Sub-Administrador',value:1},
                       {label:'Editor',value:2}
                   ]}
                   placeholder="Selecciona un tipo de usuario"
                  />
                )}
                rules={{required:true}}
             />
            {errors.type_user && <p className="text-danger">El tipo de usuario es un campo requerido.</p>}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="primary" type="submit">
            Guardar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
    );
};

export default ModalUser;