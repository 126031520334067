type District = {
  ID_Distrito: number;
  No_Romano: string;
  Nombre: string;
  Nominal: number;
  Secciones: string[];
};
type District_Format = {
  id_district: number;
  num_roman: string;
  name: string;
  nominal: number;
  sections: any;
};

type Political = {
  Abreviatura: string;
  Año_fundación: string;
  Ideologia: string;
  Imagen: string;
  Nombre: string;
};

type PoliticalFormat = {
  abbreviation: string;
  foundation_year: string;
  ideology: string;
  image_path: string;
  name: string;
};

type Commission = {
  ID:number | string;
  Nombre: string;
  No_Sesiones: number;
  Descripcion: string;
};

type CommissionFormat = {
  commission_id:string | number;
  name: string;
  no_sessions_commission:number;
  description: string;
};

type RankingExcel ={
  ID:string;
  No_de_Asistencias_del_Diputado:number;
  No_de_Asistencias_a_Comision_del_Diputado:number;
  No_de_Participaciones_en_Sesiones_Plenarias_del_Diputado:number;
  No_de_Iniciativas_Presentadas_del_Diputado:number;
  No_de_Iniciativas_Presentadas_al_Congreso_del_Diputado:number;
  No_de_Iniciativas_Aprobadas_del_Diputado:number;
  Valor_Asistencia:string;
  Valor_Asistencia_a_Comision:string;
  Valor_Participacion_en_Pleno:string;
  Valor_Iniciativas_Presentadas:string;
  Valor_Iniciativas_Aprobadas:string;
  Valor_Transparencia:string;
  Valor_Total:string;
}

export const renameDataExcelDistrict = (district: District) => {
  let sectionArray = convertSectionsStringToArray(district.Secciones, ",");

  const data: District_Format = {
    id_district: district.ID_Distrito || Math.random(),
    num_roman: district.No_Romano || "",
    name: district.Nombre || "",
    nominal: district.Nominal || 0,
    sections: sectionArray || [],
  };

  return data;
};

export const renameDataExcelPolitical = (political: Political) => {
  const data: PoliticalFormat = {
    abbreviation: political.Abreviatura || "",
    foundation_year: political.Año_fundación || "",
    ideology: political.Ideologia || "",
    image_path: political.Imagen || "",
    name: political.Nombre || "",
  };

  return data;
};

export const renameDataExcelCommission = (commission: Commission) => {
  const data: CommissionFormat = {
    commission_id: commission.ID || "",
    name: commission.Nombre || "",
    no_sessions_commission: commission.No_Sesiones || 1 ,
    description: commission.Descripcion || "",
  };

  return data;
};

const convertSectionsStringToArray = (sections: any, separator: string) => {
  const arrayString = sections.split(separator);
  const sectionsArray = arrayString.map((section:string)=>{
    return section.trim();
  })
  return sectionsArray;
};

export const renameDataExcelRanking = (rankingList:RankingExcel) =>{
  let id = rankingList.ID
  let percentile_Indicators = {
    "percentile_Indicators.assistances": rankingList.Valor_Asistencia,
    "percentile_Indicators.commisions_assistances":rankingList.Valor_Asistencia_a_Comision,
    "percentile_Indicators.full_participation":rankingList.Valor_Participacion_en_Pleno,
    "percentile_Indicators.initiatives_approved":rankingList.Valor_Iniciativas_Aprobadas,
    "percentile_Indicators.initiatives_presented":rankingList.Valor_Iniciativas_Presentadas,
    "percentile_Indicators.transparency":rankingList.Valor_Transparencia,
    "percentile_Indicators.total":rankingList.Valor_Total,
 
  };
  let indicators = {
    "indicators.initiatives_approved_politician":rankingList.No_de_Iniciativas_Aprobadas_del_Diputado,
    "indicators.initiatives_presented_congress":rankingList.No_de_Iniciativas_Presentadas_al_Congreso_del_Diputado,
    "indicators.initiatives_presented_politician":rankingList.No_de_Iniciativas_Presentadas_del_Diputado,
    "indicators.participations_in_plenary_session_politician":rankingList.No_de_Participaciones_en_Sesiones_Plenarias_del_Diputado,
    "indicators.politician_assistance": rankingList.No_de_Asistencias_del_Diputado,
    "indicators.politician_assistance_commission":rankingList.No_de_Asistencias_a_Comision_del_Diputado,
  };
  const data = { ...indicators, ...percentile_Indicators };
  return {
    id,
    data
  }
}
