import { useState } from "react";
import { Form , Button , Table} from "react-bootstrap";
import Select from "react-select";
import { showAlert } from "../../helpers/messagesNotifications";
import { useAppSelector } from "../../hooks/redux-hooks";
import { Commissions ,  } from "../../interfaces/politiciansInterface";

interface Props {
    commissions:Array<Commissions>,
    setCommissions:React.Dispatch<React.SetStateAction<Commissions[]>>
}

const CommissionsForm = ({ commissions:commissionsList , setCommissions }: Props) => {
  const { commissions , assignmentCommissions } = useAppSelector((state) => state.dataSelects);

  const [assignment, setAssignment] = useState({
    label:'',
    value:''
  });
  const [commission, setCommission] = useState({
      label:'',
      value:''
  });
  

  const handleChangeCommission = (value: any) => {
    setCommission(value);
  };

  const handleChangeAssignment = (value: any) => {
    setAssignment(value);
  };

  const handleClickAddCommissions = () =>{
    let existCommission = commissionsList.some(e=>e['commission_Id'] === commission.value );   
    if(existCommission){
        showAlert(
            'Ups, acción no permitida',
            'La comisión seleccionada ya ha sido asignada previamente',
            'warning'
        );
        return;
    }

    if(commission.label === ''){
      showAlert(
        'Ups, acción no permitida',
        'No has seleccionado ninguna comisión',
        'warning'
    );
       return;
    }

    if(assignment.label === ''){
      showAlert(
        'Ups, acción no permitida',
        'No has seleccionado un cargo para esta comisión',
        'warning'
    );
       return;
    }


    setCommissions([
        ...commissionsList,
        {commission:commission.label ,
         commission_Id:commission.value,
         assignment:assignment.label,
         assignment_id:assignment.value 
        }
    ]);

    setAssignment({
        label:'',
        value:''
    });

    setCommission({
        label:'',
        value:''
    })
  }

  const optionsCommissions = commissions.map((commission: any) => {
    const option = { label: commission.name, value: commission.commission_id};
    return option;
  });

  const optionsAssignments = assignmentCommissions.map((assign: any) => {
    const option = { label: assign.name, value: assign.assignmentID };
    return option;
  });

  const handleDeleteCommissions = (data:any) =>{
    const newList = commissionsList.filter(c=>c.commission_Id  !== data.commission_Id);
    setCommissions(newList);
  }

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>Selecciona una comisión</Form.Label>
        <Select
          options={optionsCommissions}
          placeholder="ej: Planeación y Gasto Público"
          onChange={handleChangeCommission}
          value={commission}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Selecciona cargo de comisión</Form.Label>
        <Select
          options={optionsAssignments}
          placeholder="ej: Secretario"
          onChange={handleChangeAssignment}
          value={assignment}
        />
      </Form.Group>
      <Button className="w-100 mb-3" onClick={handleClickAddCommissions}>Agregar</Button>
      <Form.Group>

        <Table responsive>
          <thead>
              <tr>
                  <th>Comisión</th>
                  <th>Cargo</th>
                  <th>Opciones</th>
              </tr>
          </thead>
          <tbody>
          {
              !commissionsList.length ?
              <tr>
                  <td colSpan={3} align="center">No hay comisiones asignadas al diputado</td>
              </tr>
              :
              commissionsList.map((params:any)=>{
                const handleDelete = (param:any) =>{
                    handleDeleteCommissions(param)
                }
                return(
                <tr key={params.commission_Id}>
                <td align="center">
                    <span className="d-inline-block text-truncate" 
                    style={{maxWidth:'100px'}}
                    >
                        {params.commission}
                    </span>
                </td>
                <td align="center">
                    <span className="d-inline-block text-truncate" 
                    style={{maxWidth:'100px'}}
                    >
                        {params.assignment}
                    </span>
                </td>
                <td align="center">
                <Button onClick={()=>handleDelete(params)} variant="danger">
                 <i className="far fa-trash-alt"/>
                </Button>
                </td>
            </tr>
              )})
          }
          </tbody>
        </Table>
      </Form.Group>
    </>
  );
};

export default CommissionsForm;
