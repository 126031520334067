import { AssignmentCommissionsInterface } from "../interfaces/assignCommissionsInterfaces";
import { types } from "../types/types";

const initialState:AssignmentCommissionsInterface = {
    assignmentCommissions:[],
    update:false,
    assignmentCommissionSelected:null,
}
export const assignmentCommissionReducer = (state = initialState , action:any) =>{
    switch (action.type) {
        case types.assignmentLoad:
            return{
            ...state,
            assignmentCommissions: action.payload
            };
        case types.assignmentAddNew:
            return{
                ...state,
                assignmentCommissions:[...state.assignmentCommissions , action.payload ]
            }
        case types.assignmentActiveUpdated:
            return{
                ...state,
                update:true,
                assignmentCommissionSelected:action.payload
            }
        case types.assignmentUpdate:
            return{
                ...state,
                assignmentCommissions:state.assignmentCommissions.map(assignment=>assignment.id === action.payload.id ? action.payload : assignment),
                update:false,
                assignmentCommissionSelected:null
            }
        case types.legislatureDelete:
            return{
                ...state,
                assignmentCommissions:state.assignmentCommissions.filter(assignment =>assignment.id !== action.payload),
            }
        case types.assignmentResetState:
            return{
                ...state,
                update:false,
                assignmentCommissionSelected:null
            }
        default:
        return state;
    }
}