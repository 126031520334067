export const types ={
    login:'[Auth] Login',
    logout:'[Auth] Logout',
    resetPassword:'[Auth] Reset Password',

    uiStartLoading:'[Auth] Start Loading',
    uiFinishLoading:'[Auth] Finish Loading',

    loadRankigPoliticians:'[Ranking] get the data for the ranking',
    rankingUpdate:'[Ranking] update rankig and get new data',
    
    dataSelectPoliticalParties:'[DataSelect] get the data for the select  politicalParties',
    dataSelectDistricts:'[DataSelect] get the data for the select districts',
    dataSelectLegislatures:'[DataSelect] get the data for the select legislatures',
    dataSelectCommissions:'[DataSelect] get the data for the select commissions',
    dataSelectPolitician:'[DataSelect] get the data for the select politician',
    dataSelectAssignment:'[DataSelect] get the data for the select assignment commissions',

    legislatureAddNew: '[Legislature] New Legislature',
    legislaturesLoad:'[Legislature] Loading Legislatures',
    legislaturesActiveUpdated:'[legislature] Active Updated and Select Legislature',
    legislatureUpdate:'[Legislature] Update Legislature',
    legislatureDelete: '[Legislature] Delete Legislature',
    legislatureResetState:'[Legislature] Reset Legislature State',

    commissionAddNew: '[Commission] New commission',
    commissionsLoad:'[Commission] Loading commissions',
    commissionsActiveUpdated:'[Commission] Active Updated and Select commission',
    commissionUpdate:'[Commission] Update commission',
    commissionDelete: '[Commission] Delete commission',
    commissionLogoutCleaning:'[Commission] Logout Cleaning',
    commissionResetForm:'[Commission] Reset Form Commission',

    politicalPartyAddNew: '[PoliticalParty] New politicalParty',
    politicalPartiesLoad:'[PoliticalParties] Loading politicalParties',
    politicalPartiesActiveUpdated:'[PoliticalParty] Active Updated and Select PoliticalParty',
    politicalPartyUpdate:'[PoliticalParty] Update PoliticalParty',
    politicalPartyDelete: '[politicalParty] Delete politicalParty',
    politicalPartyReset: '[PoliticalParty] Reset politicalParty',
    politicalPartiesLogoutCleaning:'[politicalParties] Logout Cleaning',

    districtsLoad:'[District] Loading districts',
    districtAddNew:'[District] New district',
    districtActiveUpdated:'[District] Active Updated and Select district',
    districtShowSections:'[District] Show sections the district',
    districtUpdate:'[District] Update district',
    districtDelete: '[District] Delete district',
    districtResetState:'[District] Reset state district',

    politiciansLoad:'[Politician] Loading politicians',
    politicianAddNew:'[Politician] Add New politician',
    politicianActiveUpdated:'[Politician] Active Updated and select politician',
    politicianUpdate:'[Politician] Update politician',
    politicianShow:'[Politician] Show detail politiian',
    politicianDelete:'[Politician] Delete politician',
    politicianResetState:'[Politician] Reset state politician',

    globalSettingsLoad:'[Global-Settings] Loading Global-Settings',
    globalSettingsLoadAddNew:'[Global-Settings] Add New Global-Setting',
    globalSettingsLoadActiveUpdated:'[Global-Settings] Active Updated and select Global-Settings',
    globalSettingsLoadUpdate:'[Global-Settings] Update Global-Settings',
    globalSettingsLoadDelete:'[Global-Settings] Delete Global-Settings',
    globalSettingsLoadResetState:'[Global-Settings] Reset state Global-Settings',

    usersLoad:'[Users] Loading users',
    usersLoadAddNew:'[Users] Add new user',
    userLoadActiveUpdate:'[Users] Active Updated and select user',
    userLoadUpdate:'[Users] Update User',

    assignmentAddNew: '[Assignment Commission] New assignmentCommission',
    assignmentLoad:'[Assignment Commission] Loading assignmentCommissions',
    assignmentActiveUpdated:'[Assignment Commission] Active Updated and Select assignmentCommission',
    assignmentUpdate:'[Assignment Commission] Update assignmentCommission',
    assignmentDelete: '[Assignment Commission] Delete assignmentCommission',
    assignmentResetState:'[Assignment Commission] Reset assignmentCommission State',



}