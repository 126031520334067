import { useMemo } from "react";
import { Col, Row, Button } from "react-bootstrap";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { startUpdateRankingImportExcel } from "../../actions/rankingActions";
import {
  COLUMNS_RANKING,
} from "../../helpers/columnsTables";
import { renameDataExcelRanking } from "../../helpers/excelImportRenameData";
import { showAlert } from "../../helpers/messagesNotifications";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import useHandleShowComponents from "../../hooks/useHandleShowComponents";
import useImportExcel from "../../hooks/useImportExcel";
import InputUpload from "../uiComponents/inputUpload";
import PaginationTable from "../uiComponents/paginationTable";
import SearchTextComponet from "../uiComponents/searchTextComponet";
import TableComponent from "../uiComponents/tableComponent";
import ModalRanking from "./ModalRanking";

const RankingsTable = () => {
  const dispatch = useAppDispatch();
  const { politicians } = useAppSelector((state) => state.ranking);
  const { type_user } = useAppSelector((state) => state.auth);
  const { setDataList } = useImportExcel();
  const columns = useMemo(() => COLUMNS_RANKING, []);
  const data = useMemo(
    () =>
      politicians.sort(
        (a: any, b: any) =>
          a.percentile_Indicators.total > b.percentile_Indicators.total && -1
      ),
    [politicians]
  );

  const {
    state,
    headerGroups,
    page,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    nextPage,
    previousPage,
    gotoPage,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy, usePagination);
  const { show, handleShow, handleClose } = useHandleShowComponents();
  const { pageIndex, globalFilter } = state;

  const updateRankingList = (dataList: any) => {
    dataList.forEach((ranking: any) => {
      const {id,data} = renameDataExcelRanking(ranking);
      dispatch(startUpdateRankingImportExcel(id,data));
    });
    setDataList([]);
    showAlert(
      "Importación Exitosa",
      "La importación se ha guardado de forma correcta",
      "success"
    );
  };



  return (
    <>
      <Row className="mx-2">
        <Col className="my-3" xs={12} lg={4} xl={6}>
          <Button variant="success" onClick={handleShow}>
          <i className="fas fa-edit" />
          </Button>
          {
            type_user !== 2 ? 
            <InputUpload uploadDataFunction={updateRankingList} />
             :
            null
          }
          
        </Col>
        <Col className="my-3 d-flex justify-content-end" xs={12} lg={6} xl={6}>
          <SearchTextComponet
            textDescription="Buscar..."
            filter={globalFilter}
            setFilter={setGlobalFilter}
          />
        </Col>
      </Row>
      <TableComponent
        page={page}
        prepareRow={prepareRow}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
      />
      <PaginationTable
        pageIndex={pageIndex}
        pageCount={pageCount}
        pageOptions={pageOptions}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
      />
      <ModalRanking open={show} handleClose={handleClose} />
    </>
  );
};

export default RankingsTable;
