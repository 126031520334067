export const calculateRanking = (indicadores: any, globalConfig: any , commission:any) => {  
  const assistances =
    indicadores.politician_assistance /
    (globalConfig[0] as any).plenarySessions;
  const commisions_assistances =
    indicadores.politician_assistance_commission /
    commission;
  const full_participation =
    indicadores.participations_in_plenary_session_politician /
    (globalConfig[0] as any).sessions_per_period;
  const initiatives_presented =
    indicadores.initiatives_presented_politician /
    indicadores.initiatives_presented_congress;
  const initiatives_approved =
    indicadores.initiatives_approved_politician /
    indicadores.initiatives_presented_politician;
  const total =
    Number(assistances) +
    Number(commisions_assistances) +
    Number(full_participation) +
    Number(initiatives_presented) +
    Number(initiatives_approved) +
    Number(indicadores.transparency);
  let percentile_Indicators = {
    "percentile_Indicators.assistances": assistances.toFixed(2),
    "percentile_Indicators.commisions_assistances": commisions_assistances.toFixed(2),
    "percentile_Indicators.full_participation": full_participation.toFixed(2),
    "percentile_Indicators.initiatives_approved": initiatives_approved.toFixed(2),
    "percentile_Indicators.initiatives_presented": initiatives_presented.toFixed(2),
    "percentile_Indicators.transparency":Number(indicadores.transparency).toFixed(2),
    "percentile_Indicators.total": total.toFixed(2),
  };
  let indicators = {
    "indicators.initiatives_approved_politician":
      indicadores.initiatives_approved_politician,
    "indicators.initiatives_presented_congress":
      indicadores.initiatives_presented_congress,
    "indicators.initiatives_presented_politician":
      indicadores.initiatives_presented_politician,
    "indicators.participations_in_plenary_session_politician":
      indicadores.participations_in_plenary_session_politician,
    "indicators.politician_assistance": indicadores.politician_assistance,
    "indicators.politician_assistance_commission": indicadores.politician_assistance_commission
  };

  const data = { ...indicators, ...percentile_Indicators };
  return data;
};


export const convertDataExcelToPoliticiansData = (politician:any) =>{

  const data = {
    name: politician.Nombre || '',
    image_path: politician.foto_diputado || '',
    radiography_path: politician.imagen_radiografia || '',
    date_of_birthday: politician.Fecha_de_Nacimiento || '',
    gender: politician.genero || '',
    academic_degree: { label: politician.grado_Academico || '', value: politician.grado_Academico || '' },
    last_job: politician.ultimo_cargo || '',
    hobbies: politician.hobbies || '',
    fan_page_facebook: politician.fan_page_facebook || '',
    no_followers_facebook: politician.no_seguidores_facebook || 0,
    personal_account_facebook: politician.cuenta_personal_de_facebook || '',
    no_followers_twitter: politician.no_seguidores_twitter || 0,
    account_twitter: politician.cuenta_de_twitter || '',
    account_instagram: politician.cuenta_de_instagram || '',
    no_followers_instagram: politician.no_seguidores_instagram || 0,
    activity_report: politician.Reporte_de_Actividades,
    election: politician.Eleccion || '',
    month_presented: politician.mes_presentado || '',
    year_presented: politician.año_presentado || '',
    three_of_three: politician.tres_de_tres || '',
    political_party: { label:`${politician.abreviacion || ''}-${politician.partido_politico || ''}`, value: politician.abreviacion || ''},
    district: { label:`${politician.distrito_numero_romano || ''}-${politician.distrito || ''}`, value: politician.id_distrito || ''},
    legislature: { label: politician.legislatura || '', value: politician.legislatura || ''},
    commissions:[],
    experience_legislative:politician.Experiencia_Legislativa || '',
    website:politician.Pagina_Web || '',
    percentile_Indicators:{
      assistances: 1.00,
      commisions_assistances:1.00,
      initiatives_presented:1.00,
      initiatives_approved:1.00,
      transparency:1.00,
      full_participation:1.0,
      total:6
    },
    indicators:{
      politician_assistance: 1,
      participations_in_plenary_session_politician: 1,
      initiatives_presented_politician: 1,
      initiatives_approved_politician: 1,
      initiatives_presented_congress: 1
    }
   }

   return data;
}
