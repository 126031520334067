import { useEffect } from "react";
import { Card, Container, Row } from "react-bootstrap";
import { startLoadLegislatures } from "../../actions/legislatureActions";
import LegislaturesTable from "../../components/legislatureComponents/legislaturesTable";
import { useAppDispatch } from "../../hooks/redux-hooks";
import Dashboard from "../../layouts/Dashboard/Dashboard";

import useIsMounted from "../../hooks/useIsMounted";

const LegislatureScreen = () => {
  const dispatch = useAppDispatch();
  const isMounted = useIsMounted();

  useEffect(() => {
    document.title = "Observatorio Legislativo - Legislaturas";
  }, []);

  useEffect(() => {
    if (isMounted()) {
      dispatch(startLoadLegislatures());
    }
    // eslint-disable-next-line
  }, [isMounted]);

  return (
    <Dashboard>
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <Card.Header className="border-0">
                <h3 className="mb-0"> Legislaturas</h3>
              </Card.Header>
              <Container>
                <LegislaturesTable />
              </Container>
            </Card>
          </div>
        </Row>
      </Container>
    </Dashboard>
  );
};

export default LegislatureScreen;
