import Dashboard from "../../layouts/Dashboard/Dashboard";
import { Card, Container, Row } from "react-bootstrap";
import CommissionInCommissionsTable from "../../components/commissionInComponents/assignmentCommissionTable";
import { useAppDispatch } from "../../hooks/redux-hooks";
import useIsMounted from "../../hooks/useIsMounted";
import { useEffect } from "react";
import { startLoadAssignCommissions } from "../../actions/assignmentCommissionActions";

const AssingmentCommissionsScreen = () => {
  const dispatch = useAppDispatch();
  const isMounted = useIsMounted();

  useEffect(() => {
    document.title = "Observatorio Legislativo - Encargos de la comisión";
  }, []);

  useEffect(() => {
    if (isMounted()) {
      dispatch(startLoadAssignCommissions());
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Dashboard>
      <Container>
        <Row>
          <div className="col">
            <Card className="shadow">
              <Card.Header className="border-0">
                <h3 className="mb-0"> Cargos en Comisiones</h3>
              </Card.Header>
              <CommissionInCommissionsTable />
            </Card>
          </div>
        </Row>
      </Container>
    </Dashboard>
  );
};

export default AssingmentCommissionsScreen;
