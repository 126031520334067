import { addData, createUser, loadData , deleteUser, updateData} from "../helpers/firebaseMethods";
import { showAlert } from "../helpers/messagesNotifications";
import { UserInterface, UsersInterface } from "../interfaces/usersInterfaces"
import { types } from "../types/types"

export const startLoadUsers = () =>{
    return async (dispatch:any)=>{
     try {
        const users = await loadData('users','displayName');
        dispatch(loadUsers(users));
     } catch (error) {
         console.log(error);
     }
    }
}

export const loadUsers = (users:Array<UsersInterface>) =>({
    type:types.usersLoad,
    payload:users
});


export const startRegisterUsers = (user:UserInterface) =>{
    return async(dispatch:any)=>{
     try {
        const userRegister:any = await createUser(user);
       const {id} = await addData('users' , userRegister );
       userRegister.id = id
       dispatch(addNewUser(userRegister));
       showAlert('Registro guardado',
                  'El registro ha sido guardado de forma correcta',
                  'success'
                 );
     } catch (error) {
         console.log(error);
         showAlert('Error al actualizar',
                  'El registro no se ha guardado de forma correcta , contacta al administrador',
                  'danger'
                 );
     }
    }
}

export const addNewUser = (user:UserInterface) =>({
    type:types.usersLoadAddNew,
    payload:user
});


export const startDeleteUser = (id:string) =>{
    return (dispatch:any)=>{
      try {
        deleteUser(id);
        // deleteData('users',id)
      } catch (error) {
          console.log(error);
      }
    }
}

export const startUpdateUser = (user:any) =>{
   return async (dispatch:any , getState:any)=>{
       const { userSelected} = getState().users;
       const { type_user } = user;
     try {
         updateData('users', userSelected.id , user);
         const data = {...userSelected , type_user}
         dispatch(updateUpdate( data ));
         showAlert('Registro guardado',
                  'El registro ha sido actualizado de forma correcta',
                  'success'
                 );
     } catch (error) {
         console.log(error);
         showAlert('Error al actualizar',
                  'El registro no se ha actualizado de forma correcta , contacta al administrador',
                  'danger'
                 );
     }
   } 
}

export const activeUpdateUser = (user:any) =>({
   type: types.userLoadActiveUpdate,
   payload: user
});

export const updateUpdate = (data:any) =>({
    type:types.userLoadUpdate,
    payload:data
})