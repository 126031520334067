import { useEffect } from "react";
import Dashboard from "../../layouts/Dashboard/Dashboard";
import { Card, Container, Row } from "react-bootstrap";
import UsersTable from "../../components/usersComponents/usersTable";
import { useAppDispatch } from "../../hooks/redux-hooks";
import { startLoadUsers } from "../../actions/usersActions";
import useIsMounted from "../../hooks/useIsMounted";

const UsersScreen = () => {
  const dispatch = useAppDispatch();
  const isMounted = useIsMounted();
  useEffect(() => {
    document.title = "Observatorio Dashboard - Usuarios";
  }, []);

  useEffect(() => {
    if (isMounted()) {
      setTimeout(() => {
        dispatch(startLoadUsers());
      }, 1000);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Dashboard>
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <Card.Header className="border-0">
                <h3 className="mb-0"> Usuarios</h3>
              </Card.Header>
              <Container>
                <UsersTable />
              </Container>
            </Card>
          </div>
        </Row>
      </Container>
    </Dashboard>
  );
};

export default UsersScreen;
