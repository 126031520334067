import { useForm, SubmitHandler } from "react-hook-form";
import { startLoginEmailPassword } from "../../actions/authActions";
import { Form, Button, InputGroup } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import AuthLayout from "../../layouts/Dashboard/AuthLayout";
import { Link } from "react-router-dom";
type Inputs = {
  email: string;
  password: string;
};
const LoginScreen = () => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.ui);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const { email, password } = data;
    dispatch(startLoginEmailPassword(email, password));
  };
  return (
    <AuthLayout>
      <Form onSubmit={handleSubmit(onSubmit)} role="form">
        <div className="text-center text-muted mb-4">
          <img
            alt="google"
            src={require("../../assets/images/logo.png").default}
            width="100"
          />
        </div>
        <div className="text-center text-muted mb-4">
          <h2>Inicia Sesión</h2>
        </div>
        <Form.Group className="mb-3">
          <InputGroup className="input-group-alternative">
            <InputGroup.Text>
              <i className="fas fa-envelope-square" />
            </InputGroup.Text>
            <Form.Control
              type="email"
              placeholder="Ingresa tu correo"
              {...register("email", {
                required: true,
              })}
            />
          </InputGroup>
          {errors.email && (
            <p className="text-danger">El correo es requerido</p>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <InputGroup className="input-group-alternative">
            <InputGroup.Text>
              <i className="fas fa-lock"></i>
            </InputGroup.Text>
            <Form.Control
              type="password"
              placeholder="Ingresa tu contraseña"
              {...register("password", {
                required: true,
              })}
            />
          </InputGroup>
          {errors.password && (
            <p className="text-danger">La contraseña es requerida</p>
          )}
        </Form.Group>
        <Form.Group>
          <p>¿Olvidaste tu contraseña? <Link to={'/auth/reset-password'}>Da click en este enlace</Link></p>
        </Form.Group>
        <div className="text-center">
          <Button
            className="my-4"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Ingresar
          </Button>
        </div>
      </Form>
    </AuthLayout>
  );
};

export default LoginScreen;
