import moment from "moment";

export const years_old = (date:string) =>{
    if(date){
        const birthday = moment().diff(new Date(Date.parse(date)),"years");
        return birthday;
    }
    
}


