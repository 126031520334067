import {useMemo} from "react";
import {Col , Row , Button} from "react-bootstrap";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import Swal from "sweetalert2";
import { activeUpdated, startDeletingAssignmentCommission } from "../../actions/assignmentCommissionActions";
import {COLUMNS_COMMISSION_IN_COMMISSIONS} from "../../helpers/columnsTables";
import { useAppDispatch , useAppSelector } from "../../hooks/redux-hooks";
import useHandleShowComponents from "../../hooks/useHandleShowComponents";
import PaginationTable from "../uiComponents/paginationTable";
import SearchTextComponet from "../uiComponents/searchTextComponet";
import TableComponent from "../uiComponents/tableComponent";
import ModalAssignmentCommission from "./modalAssignmentCommission";
import InputUpload from "../uiComponents/inputUpload";

const CommissionInCommissionsTable = () => {
    const dispatch = useAppDispatch();
    const { type_user } = useAppSelector((state) => state.auth);
    const { assignmentCommissions } = useAppSelector((state)=>state.assignment);

    const columns = useMemo(
        ()=>[
            ...COLUMNS_COMMISSION_IN_COMMISSIONS,
            {
                Header:"Opciones",
                accessor:"action",
                Cell:({row}:any)=>(
                    <>
                    <Button 
                      variant="success"
                      onClick={()=>handleUpdatedAssignmentCommission(row.original)}
                    >
                       <i className="fas fa-edit" />
                    </Button>
                    { type_user !== 2 ?
              <>
              <Button variant="danger"
                onClick={()=>handleDeleteAssignmentCommission(row.original.id)}
              >
                <i className="far fa-trash-alt" />
              </Button>
              </>
              :null
            }
                    </>
                )
            }
            // eslint-disable-next-line
        ],[]);

        const data = useMemo(() => assignmentCommissions, [assignmentCommissions]);

        const {
          state,
          headerGroups,
          page,
          canNextPage,
          canPreviousPage,
          pageOptions,
          pageCount,
          getTableProps,
          getTableBodyProps,
          prepareRow,
          nextPage,
          previousPage,
          gotoPage,
          setGlobalFilter,
        } = useTable({ columns, data }, useGlobalFilter, useSortBy, usePagination);
        const { show, handleShow, handleClose } = useHandleShowComponents();
        const { pageIndex, globalFilter } = state;
      

     const handleUpdatedAssignmentCommission = (assignment:any) =>{
         dispatch(activeUpdated(assignment))
     }

     const handleDeleteAssignmentCommission = (id:string) =>{
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡Una encargo de comisión eliminado no se puede recuperar!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Eliminar!',
            cancelButtonText:'No, Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire(
                'Eliminado!',
                'La legislatura seleccionada ha sido eliminada.',
                'success'
              )
              dispatch(startDeletingAssignmentCommission(id));
            }
          })
     }


    return (
      <>
      <Row className="mx-2">
         <Col className="my-3" xs={12} lg={4} xl={6}>
           { type_user !== 2 ?
             <>
             <Button variant="success" onClick={handleShow}>
               <i className="fas fa-plus-circle" />
             </Button>
             <InputUpload />
             </>
             : null
            }
         </Col>
         <Col className="my-3 d-flex justify-content-end" xs={12} lg={6} xl={6}>
           <SearchTextComponet
             textDescription="Buscar..."
             filter={globalFilter}
             setFilter={setGlobalFilter}
           />
         </Col>
       </Row>
       <TableComponent
         page={page}
         prepareRow={prepareRow}
         getTableProps={getTableProps}
         headerGroups={headerGroups}
         getTableBodyProps={getTableBodyProps}
       />
       <PaginationTable
         pageIndex={pageIndex}
         pageCount={pageCount}
         pageOptions={pageOptions}
         canPreviousPage={canPreviousPage}
         canNextPage={canNextPage}
         nextPage={nextPage}
         previousPage={previousPage}
         gotoPage={gotoPage}
       />
        <ModalAssignmentCommission
         open={show}
         handleClose={handleClose}
        />
     </>
    );
};

export default CommissionInCommissionsTable;