import { useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { SubmitHandler, useForm  } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { startNewCommission, startUpdatedCommission , resetFormCommissions } from "../../actions/commissionActions";


interface Props {
  open: boolean;
  handleClose: () => void;
}
type Inputs = {
  id:string;
  commission_id:string;
  name: string;
  no_sessions_commission:number;
  description: string;
};
const ModalCommissions = ({ open, handleClose }: Props) => {

  const dispatch = useAppDispatch();
  const {update , commissionSelected , commissions } = useAppSelector((state)=>state.commission);
  const {loading} = useAppSelector((state)=>state.ui);
  
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<Inputs>();

  useEffect(() => {
    if(commissionSelected){
      setValue("commission_id",commissionSelected.commission_id, { shouldDirty: true })
      setValue("name",commissionSelected.name, { shouldDirty: true });
      setValue("no_sessions_commission",commissionSelected.no_sessions_commission, { shouldDirty: true });
      setValue("description", commissionSelected.description, { shouldDirty: true });
    }else{
      setValue("commission_id","", { shouldDirty: true })
      setValue("name","", { shouldDirty: true });
      setValue("no_sessions_commission",1,{ shouldDirty: true });
      setValue("description","", { shouldDirty: true });
    }
    // eslint-disable-next-line
  }, [commissionSelected , commissions ])

  const handleResetForm = () =>{
    dispatch(resetFormCommissions())
    handleClose();
  }

  const onSubmit: SubmitHandler<Inputs> = (data) => {

    if(update){
      dispatch(startUpdatedCommission(data));
    }else{
     dispatch(startNewCommission(data)); 
    }
    
    handleResetForm();
  };

  return (
    <Modal show={open} onHide={handleResetForm}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title> {update ? 'Editar Comisión' : 'Agregar Comisión'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Group className="mb-3">
            <Form.Label>Nombre ID:</Form.Label>
            <Form.Control
              type="number"
              placeholder="ej: 1"
              {...register("commission_id", {
                required: true,
              })}
            />
            {errors.commission_id && <p className="text-danger">El  ID de la comisión es requerida</p>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Nombre Comisión:</Form.Label>
            <Form.Control
              type="text"
              placeholder="ej:Agricultura y Ganadería"
              {...register("name", {
                required: true,
              })}
            />
            {errors.name && <p className="text-danger">El nombre de la comisión es requerida</p>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Número de Sesiones:</Form.Label>
            <Form.Control
              type="number"
              placeholder="ej:35"
              {...register("no_sessions_commission", {
                required: true,
              })}
            />
            {errors.no_sessions_commission && <p className="text-danger">El No. de sesiones  de la comisión es requerida</p>}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Descripción de la comisión:</Form.Label>
          <Form.Control
              as="textarea"
              aria-label="with textare"
              rows={5}
              placeholder="ej: Plantean la necesidad de que el Ejecutivo..."
              {...register("description", {
                required: false,
              })}
            />
            {errors.description && <p className="text-danger">El nombre de la descripción es requerida</p>}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleResetForm}>
            Cerrar
          </Button>
          <Button variant="primary" type="submit" disabled={loading}>
            Guardar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

ModalCommissions.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ModalCommissions;
