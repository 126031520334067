import { Route , Switch} from "react-router-dom";
import LoginScreen from "../views/auth/loginScreen";
import ResetPasswordScreen from "../views/auth/resetPasswordScreen";
const AuthRouter = () => {
    return (
        <Switch>
           <Route 
             exact 
             path="/auth/login"
             component={LoginScreen}
            /> 
            <Route 
             exact 
             path="/auth/reset-password"
             component={ResetPasswordScreen}
            /> 
        </Switch>
           
    );
};

export default AuthRouter;