import {CardBody, CardTitle, Col,Card, Row} from 'reactstrap';
interface Props{
    name:string;
    icon:string;
    num:number;
    color:string;
}
const CardInfoComponent = ({name , icon , num , color}:Props) => {
    return (
        <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {name}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {num}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className={`icon icon-shape ${color} text-white rounded-circle shadow`}>
                          <i className={icon} />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
    );
};

export default CardInfoComponent;